import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Editor from "./admin/editor";
import AdminData from "./admin/admin-data";
import BlogHome from "./posts/blog-home";
import BlogFooter from "./structure/blog-footer";
import BlogHeader from "./structure/blog-header";
import BlogIndexContainer from "./posts/blog-index-container";
import BlogSidebar from "./structure/blog-sidebar";
import BlogPostContainer from "./post/blog-post-container";
import Login from "./admin/login";
import Logoff from "./admin/logoff";
import VideoPlayList from "./pages/video-playlist";
import SearchPosts from "./posts/search-posts";
import BlogFullIndexContainer from "./posts/blog-full-index-container";
import BlogCategoryIndexContainer from "./posts/blog-category-index-container";

class Layout extends Component {
  render() {
    return (
      <div className="grid-container">
        <Router>
          <div className="grid-x grid-margin-x grid-main">
            <BlogHeader />
            <Routes>
              <Route exact path="/" element={<BlogHome />} />
              <Route exact path="/index" element={<BlogHome />} />
              <Route
                exact
                path="/liste-complete/:page"
                element={<BlogFullIndexContainer />}
              />
              <Route exact path="/p/:page" element={<BlogIndexContainer />} />
              <Route
                exact
                path="/categorie/:id.html"
                element={<BlogCategoryIndexContainer />}
              />
              <Route
                exact
                path="/categorie/:id/:page.html"
                element={<BlogCategoryIndexContainer />}
              />
              <Route exact path="/recherche" element={<SearchPosts />} />
              <Route
                exact
                path="/page/:publicID.html"
                element={<BlogPostContainer />}
              />
              <Route exact path="/video-playlist" element={<VideoPlayList />} />
              <Route exact path="/editor" element={<Editor />} />
              <Route exact path="/editor/:publicID" element={<Editor />} />
              <Route exact path="/draft/:draftKey" element={<Editor />} />
              <Route exact path="/admin-data" element={<AdminData />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/logoff" element={<Logoff />} />
            </Routes>

            <BlogSidebar />
          </div>
          <BlogFooter />
        </Router>
      </div>
    );
  }
}

export default Layout;
