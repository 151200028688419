import React, { Component } from "react";
import LabelCloud from "./label-cloud";
import VideoPreview from "./video-preview";
import { Link } from "react-router-dom";
import Delayed from "../delayed";

class BlogSidebar extends Component {
  render() {
    return (
      <aside className="page-sidebar columns medium-3 sticky-container">
        <article className="page-sidebar-article is-anchored is-at-top mb-1">
          <ul className="menu vertical">
            <li className="border-bottom-1 mb-1">
              <Link to={`/index`}>Accueil du blog</Link>
            </li>
            <li className="border-bottom-1  mb-1">
              <Link to={`/liste-complete/1`}>Liste complète</Link>
            </li>
            <li className="border-bottom-1  mb-1">
              <Link to={`/recherche`}>Recherche</Link>
            </li>
            <li className="border-bottom-1  mb-1">
              <Link to={`/video-playlist`}>Vidéos</Link>
            </li>
          </ul>
        </article>
        <Delayed waitBeforeShow={2200}>
          <VideoPreview />
        </Delayed>
        <Delayed waitBeforeShow={2200}>
          <LabelCloud />
        </Delayed>
      </aside>
    );
  }
}

export default BlogSidebar;
