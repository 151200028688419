import React, { Component } from "react";
import PostComment from "./post-comment";
import PostService from "../../services/post-service";

class PostComments extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.initialDateTime = "";
    this.state = this.getNewState();
  }
  getNewState() {
    return {
      comments: [],
      id: 0,
    };
  }
  componentDidMount() {
    this.getComments(this.props.post.publicID);
  }
  componentDidUpdate(prevProps) {
    if (this.props.post.publicID !== prevProps.post.publicID) {
      this.setState(this.getNewState());
      this.getComments(this.props.post.publicID);
    }
  }
  getComments(publicID) {
    PostService.getPostComments(publicID)
      .then((response) => {
        if (typeof response.data.responseDate !== "undefined") {
          this.initialDateTime = response.data.responseDate;
        }
        if (
          typeof response.data.results !== "undefined" &&
          typeof response.data.results.comments !== "undefined"
        ) {
          this.setState({
            comments: response.data.results.comments,
          });
        }
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });
  }
  componentWillReceiveProps(props) {
    this.getComments(props.post.publicID);
  }
  render() {
    return (
      <div>
        {this.state.comments.map((comment) => (
          <PostComment
            key={comment.publicID}
            comment={comment}
            initialDateTime={this.initialDateTime}
          />
        ))}
      </div>
    );
  }
}

export default PostComments;
