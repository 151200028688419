import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditorPost extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: null,
      displayBody: false,
    };

    this.tooglePostBody = this.tooglePostBody.bind(this);
  }
  tooglePostBody() {
    this.setState((state) => ({ displayBody: !state.displayBody }));
  }
  render() {
    return (
      <div>
        <article className="post toggleSubMenu mbl">
          <header className="toogle post-header mbl text-underline">
            <div className="post-date">{this.props.post.date}</div>
            <h1 className="post-title fs100">
              <Link
                target="_blank"
                title="Ouvrir la page"
                className="style-lien"
                to={`/page/${this.props.post.publicID}.html`}
              >
                {this.props.post.title}
              </Link>
            </h1>
          </header>

          <div className="text-right">
            <button
              type="button"
              onClick={this.tooglePostBody}
              className="toogle hollow button text-right"
            >
              ...
            </button>
          </div>

          {this.state.displayBody && (
            <section className="post-body subMenu" itemProp="articleBody">
              <section
                className="post-body"
                dangerouslySetInnerHTML={{ __html: this.props.post.body }}
              />
              <p>
                <Link
                  target="_blank"
                  className="style-lien"
                  to={`/page/${this.props.post.publicID}.html`}
                >
                  {this.props.post.title}
                </Link>
              </p>
            </section>
          )}
        </article>
      </div>
    );
  }
}

export default EditorPost;
