import React, { Component } from "react";
import DataService from "../../services/data-service";
import { useParams } from "react-router-dom";
import AuthenticationService from "../../services/authentication";

class AdminData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlFieldSet: "",
      records: [],
      obj: [],
      sectionID: 0,
      actionID: "select",
      statusMessage: "",
      recordID: 0,
      recordPublicID: 0,
    };

    //this.props = props;
    this.objectType = "Label";
    this.handleChangeObjectType = this.handleChangeObjectType.bind(this);
    this.handleChangeSelectedRecord =
      this.handleChangeSelectedRecord.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);
    this.handleChangeRecordID = this.handleChangeRecordID.bind(this);
    this.handleChangeRecordPublicID =
      this.handleChangeRecordPublicID.bind(this);
    this.handleChangeAction = this.handleChangeAction.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateObjectType(this.objectType, 0);
  }

  handleChangeObjectType(event) {
    this.setState({
      statusMessage: "",
    });
    this.updateObjectType(event.target.value, this.state.sectionID);
    event.preventDefault();
  }
  handleChangeSection(event) {
    this.setState({
      sectionID: event.target.value,
    });
    this.updateObjectType(this.objectType, event.target.value);
    event.preventDefault();
  }
  handleChangeAction(event) {
    this.setState({
      actionID: event.target.value,
    });
    event.preventDefault();
  }
  handleChangeRecordPublicID(event) {
    this.setState({
      recordPublicID: event.target.value,
    });
    event.preventDefault();
  }
  handleChangeRecordID(event) {
    this.setState({
      recordID: event.target.value,
    });
    event.preventDefault();
  }
  handleChangeSelectedRecord(event) {
    this.setState({
      recordPublicID: event.target.value,
      statusMessage: "",
    });
    event.preventDefault();
  }
  updateObjectType(objectType, sectionID) {
    this.objectType = objectType;
    DataService.getObjectDefinition(objectType, sectionID)
      .then((responseData) => {
        if (
          typeof responseData.data.results !== "undefined" &&
          typeof responseData.data.results.fieldset !== "undefined"
        ) {
          this.setState({
            htmlFieldSet: responseData.data.results.fieldset,
            records: responseData.data.results.records,
            obj: responseData.data.results.obj,
          });
        }

        if (typeof responseData.data.status !== "undefined") {
          this.setState({
            statusMessage:
              responseData.data.status + " - " + responseData.data.responseDate,
          });
        }
      })
      .catch((error) => {
        this.setState({
          statusMessage: error.message,
        });
      });
  }
  handleSubmit(event) {
    this.submitForm(this.state.actionID);
    event.preventDefault();
  }
  submitForm(actionID) {
    const form = document.getElementById("dataform");
    const data = this.serialize(form);
    const JSONData = JSON.stringify(data);

    if (this.objectType !== "object") {
      DataService.updateObject(
        this.objectType,
        JSONData,
        actionID,
        this.state.sectionID,
        this.state.recordID,
        this.state.recordPublicID
      )
        .then((responseData) => {
          if (typeof responseData.data.results !== "undefined") {
            if (typeof responseData.data.results.fieldset !== "undefined") {
              this.setState({
                htmlFieldSet: responseData.data.results.fieldset,
              });
            }
            if (typeof responseData.data.results.records !== "undefined") {
              this.setState({
                records: responseData.data.results.records,
              });
            }
            if (typeof responseData.data.results.id !== "undefined") {
              this.setState({
                recordID: responseData.data.results.id,
              });
            }
          }

          if (typeof responseData.data.status !== "undefined") {
            this.setState({
              statusMessage:
                responseData.data.status +
                " - " +
                responseData.data.responseDate,
            });
          }
        })
        .catch((error) => {
          this.setState({
            statusMessage: error.message,
          });
        });
    }
  }
  render() {
    if (AuthenticationService.checkLocalAuthUser() === false) {
      return (
        <div className="page-page medium-9 cell">
          <main className="card page-main">
            <div className="card-section">Accès non autorisé</div>
          </main>
        </div>
      );
    }

    return (
      <div className="page-page medium-9 cell">
        <main className="card page-main">
          <article className="card-section mb-3" id="post-editor">
            <form
              className="mb-2"
              id="dataform"
              name="dataform"
              onSubmit={this.handleSubmit}
            >
              <fieldset className="mb-2">
                <ul className="menu vertical">
                  <li className="mb-1">
                    <label htmlFor="object_type">Objet</label>
                    <select
                      defaultValue="post"
                      id="object_type"
                      onChange={this.handleChangeObjectType}
                    >
                      <option value="Label">Libellé</option>
                      <option value="Image">Image</option>
                      <option value="Person">Personne</option>
                      <option value="Comment">Commentaire</option>
                      <option value="Album">Album</option>
                      <option value="Track">Titre</option>
                      <option value="Version">Version</option>
                      <option value="RTrackPerson">RTrackPerson</option>
                    </select>
                  </li>

                  <li className="mb-1">
                    <label htmlFor="sectionID">Section</label>
                    <input
                      type="number"
                      id="sectionID"
                      name="sectionID"
                      value={this.state.sectionID}
                      onChange={this.handleChangeSection}
                      placeholder="sectionID"
                      required="required"
                      min="0"
                      max="9"
                      step="1"
                    />
                  </li>
                  <li className="mb-1">
                    <label htmlFor="recordID">ID</label>
                    <input
                      type="number"
                      id="recordID"
                      name="recordID"
                      value={this.state.recordID}
                      onChange={this.handleChangeRecordID}
                      placeholder="recordID"
                      step="1"
                    />
                  </li>
                  <li className="mb-1">
                    <label htmlFor="recordPublicID">Public ID</label>
                    <input
                      type="text"
                      id="recordPublicID"
                      name="recordPublicID"
                      value={this.state.recordPublicID}
                      onChange={this.handleChangeRecordPublicID}
                      placeholder="recordPublicID"
                      step="1"
                    />
                  </li>
                  <li className="mb-1">
                    <label htmlFor="selectedRecord">Liste</label>
                    <select
                      name="records-list"
                      onChange={this.handleChangeSelectedRecord}
                      id="records-list"
                    >
                      <option value="0" label="0"></option>
                      {this.state.records.map((record) => (
                        <option
                          key={record.publicID}
                          value={record.publicID}
                          label={record.string}
                        ></option>
                      ))}
                    </select>
                  </li>
                  <li className="mb-1">
                    <button
                      type="button"
                      className="button btn-md"
                      onClick={() => this.submitForm("select")}
                    >
                      Rechercher
                    </button>
                  </li>
                </ul>
              </fieldset>

              <p>{this.state.statusMessage}</p>

              <div
                dangerouslySetInnerHTML={{ __html: this.state.htmlFieldSet }}
              />

              <ul className="menu vertical">
                <li className="mb-1">
                  <button
                    type="button"
                    className="button btn-md"
                    onClick={() => this.submitForm("search")}
                  >
                    Rechercher
                  </button>
                </li>

                <li className="mb-1">
                  <label htmlFor="action">Action</label>
                  <select
                    defaultValue="post"
                    id="action"
                    onChange={this.handleChangeAction}
                  >
                    <option value="select">Sélectionner</option>
                    <option value="update">Modifier</option>
                    <option value="insert">Insérer</option>
                    <option value="delete">Supprimer</option>
                  </select>
                </li>
              </ul>

              <div>
                <button
                  id="submit"
                  name="submit"
                  className="button btn-primary btn-md"
                >
                  Envoyer
                </button>
              </div>
              <div>
                <b>{this.state.statusMessage}</b>
              </div>
            </form>
          </article>
        </main>
      </div>
    );
  }

  serialize(form) {
    const o = {};

    if (!form || form.nodeName !== "FORM") {
      return;
    }
    let i,
      j = [];
    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
      if (form.elements[i].name === "") {
        continue;
      }

      switch (form.elements[i].nodeName) {
        case "INPUT":
          switch (form.elements[i].type) {
            case "text":
            case "tel":
            case "email":
            case "hidden":
            case "password":
            case "number":
            case "button":
            case "reset":
            case "submit":
            case "url":
              o[form.elements[i].name] = form.elements[i].value;
              break;
            case "checkbox":
            case "radio":
              if (form.elements[i].checked) {
                o[form.elements[i].name] = form.elements[i].value;
              }
              break;
            default:
              break;
          }
          break;
        case "file":
          break;
        case "TEXTAREA":
          o[form.elements[i].name] = form.elements[i].value;
          break;
        case "SELECT":
          switch (form.elements[i].type) {
            case "select-one":
              o[form.elements[i].name] = form.elements[i].value;
              break;
            case "select-multiple":
              for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                if (form.elements[i].options[j].selected) {
                  o[form.elements[i].name] = form.elements[i].options[j].value;
                }
              }
              break;
            default:
              break;
          }
          break;
        case "BUTTON":
          switch (form.elements[i].type) {
            case "reset":
            case "submit":
            case "button":
              o[form.elements[i].name] = form.elements[i].value;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    return o;
  }
}

export default (props) => <AdminData {...props} params={useParams()} />;
