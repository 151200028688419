import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";

class LabelCloud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "Libellés",
      labels: [],
      id: 0,
    };
  }

  componentDidMount() {
    this.getLabels();
  }

  getLabels() {
    PostService.getLabels()
      .then((response) => {
        response.data.results.labels.forEach((element) => {
          element.class = "label-size label-size-" + element.class;
        });

        this.setState({
          labels: response.data.results.labels,
        });
      })
      .catch((error) => {
        this.setState({
          status: error.message,
          labels: [],
        });
      });
  }

  render() {
    return (
      <div>
        <section className="text-center mb-2 page-sidebar-article labels-cloud">
          <h1>{this.state.status}</h1>
          {this.state.labels.map((label) => (
            <span
              key={label.publicID}
              className={"mb-3 label-size label-size-" + label.class}
            >
              <Link to={`/categorie/${label.publicID}.html`}>
                <span>{label.title} </span>
                <span className="label-compte">({label.occurrences})</span>
              </Link>
            </span>
          ))}
        </section>
      </div>
    );
  }
}

/*
<p className="mtm text-center">
	<a className="text-underline" title="Liste des libellés sur ce blog"
		href="">
		Liste des libellés
	</a>
</p>
*/
export default LabelCloud;
