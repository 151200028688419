import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "../../services/authentication";
import PropTypes from "prop-types";

class BlogIndexPost extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
  }
  render() {
    return (
      <article
        key={this.post.publicID}
        id="post-{post.publicID}"
        className="card"
        itemProp="blogPost"
        itemScope="itemscope"
        itemType="http://schema.org/BlogPosting"
      >
        <div className="card-section">
          <div className="">
            <Link to={`/page/${this.post.publicID}.html`}>
              <div>
                <header>
                  <time
                    className="post-date fs100"
                    dateTime="{post.dateIso }"
                    itemProp="datePublished"
                  >
                    {this.post.dateDay}
                  </time>
                  <h1 className="fs100" itemProp="name">
                    {this.post.title}
                  </h1>
                </header>
                {this.post.summary !== "" && (
                  <section className="post-summary" itemProp="articleBody">
                    {this.post.summary} [...]
                  </section>
                )}
              </div>
            </Link>
            {AuthenticationService.checkLocalAuthUser() && (
              <footer className="text-right mt-1">
                <Link
                  className="hollow button text-right"
                  to={`/editor/${this.post.publicID}`}
                >
                  Modifier
                </Link>
              </footer>
            )}
          </div>
        </div>
      </article>
    );
  }
}

BlogIndexPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default BlogIndexPost;
