import React, { Component } from "react";

class LoadingBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }
  componentDidMount() {
    this.startProgressBar();
  }
  startProgressBar() {
    // Start the progress bar simulation
    this.progressInterval = setInterval(() => {
      this.setState((prevState) => {
        const newProgress = (prevState.progress + 1) % 100;
        return { progress: newProgress };
      });
    }, 10);
  }
  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.progressInterval);
  }
  render() {
    const progressBarContainerStyle = {
      position: "relative",
      width: "100%",
      height: "20px",
      background: "#eee",
      borderRadius: "10px",
      overflow: "hidden",
    };

    const progressBarStyle = {
      width: `${this.state.progress}%`,
      height: "100%",
      borderRadius: "10px",
      transition: "width 1s ease", // Ajout d'une transition pour une animation fluide
      background: `hsl(${this.state.progress * 1.2}, 70%, 50%)`, // Changement de couleur basé sur la valeur de progression
    };

    return (
      <article className="card blog-post">
        <div className="card-section">
          <p>Chargement en cours...</p>
          <div style={progressBarContainerStyle}>
            <div style={progressBarStyle}></div>
          </div>
        </div>
      </article>
    );
  }
}

export default LoadingBar;
