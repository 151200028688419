import React, { Component } from "react";
import AuthenticationService from "../../services/authentication";
import { Link } from "react-router-dom";

class Logoff extends Component {
  constructor(props) {
    super(props);
    this.state = this.getCurrentState();
    this.setStatus = this.setStatus.bind(this);
  }
  componentDidMount() {
    this.logOffAction();
  }
  logOffAction() {
    if (AuthenticationService.getIsLocalUser()) {
      //logoff
      AuthenticationService.checkLogin(true)
        .then((message) => {
          this.setStatus(message);
		  window.location.reload();
        })
        .catch((error) => {
          this.setStatus(error.message);
        });
    }
  }
  getCurrentState() {
    return {
      user: {
        username: AuthenticationService.getLocalUserName(),
        password: "",
        auth: AuthenticationService.getIsLocalUser(),
      },
      status: AuthenticationService.getCurrentStatus(),
    };
  }
  setStatus(status) {
    this.setState({
      status: status,
    });
  }
  render() {
    window.scrollTo(0, 0);
    if (AuthenticationService.getIsLocalUser()) {
      return (
        <div className="page-main medium-9 cell">
          <main className="card">
            <article className="card-section post">
              <header className="post-header mb-2">
                <h1 className="h4">{this.state.status}</h1>
              </header>
            </article>
          </main>
        </div>
      );
    }
    return (
      <div className="page-main medium-9 cell">
        <main className="card">
          <article className="card-section post">
            <header className="post-header mb-2">
              <h1 className="h4">{this.state.status}</h1>
            </header>
            <section className="post-body">
              <Link className="button" to="/login">
                Connexion
              </Link>
            </section>
          </article>
        </main>
      </div>
    );
  }
}

export default Logoff;
