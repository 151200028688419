import React, { Component } from "react";
import { Link } from "react-router-dom";
import BlogIndexPost from "./blog-index-post";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";

class BlogIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      title: "Chargement...",
      loading: false,
      page: 1,
    };
    this.page = 1;
    this.previousPage = 0;
  }
  componentDidMount() {
    this.displayPage();
  }
  componentDidUpdate(prevProps) {
    this.displayPage(prevProps);
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    } else {
      this.page = 1;
    }
  }
  displayPage() {
    this.setPage();

    if (this.page !== this.previousPage) {
      this.displayPosts(this.page);
      this.previousPage = this.page;
      window.scrollTo(0, 0);
    }
  }
  displayPosts(p) {
    if (p > 0) {
      try {
        this.setState({
          loading: true,
        });
        PostService.getPosts(p, 11)
          .then((responseData) => {
            this.setState({
              posts: responseData.data.results.posts,
              pagination: responseData.data.results.pagination,
              title: "",
              page: responseData.data.results.p,
              loading: false,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              posts: [],
              pagination: [],
              title: error,
              loading: false,
              page: 1,
            });
          });
      } catch (e) {
        this.setState({
          posts: [],
          pagination: [],
          title: "Erreur",
          loading: false,
          page: 1,
        });
      }
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Aucune page définie",
        loading: false,
      });
    }
  }
  render() {
    return (
      <main className="page-main medium-9 columns cell padding-x">
        {this.state.posts.length === 0 && this.state.loading && <LoadingBar />}
        {this.state.posts.length === 0 && !this.state.loading && (
          <ErrorBlock message={this.state.title} />
        )}
        {this.state.posts.length > 0 && (
          <section>
            {this.state.title !== "" && (
              <h2 className="text-center h5 mb-1">{this.state.title}</h2>
            )}
            {this.state.posts.map((post) => (
              <BlogIndexPost key={post.publicID} post={post} />
            ))}

            <div className="text-center mb-1">Page {this.state.page}</div>

            <nav className="pagination text-center">
              <ul>
                {this.page > 1 && (
                  <li className="next">
                    <Link to={`/p/1`}>Retour page 1</Link>
                  </li>
                )}
                {this.state.pagination.map(
                  (page, index) =>
                    index === 0 &&
                    page !== "" && (
                      <li key="prev" className="prev">
                        <Link to={`/p/${page}`}>Page suivante</Link>
                      </li>
                    )
                )}
                {this.state.pagination.map(
                  (page, index) =>
                    index === 1 &&
                    page !== "" && (
                      <li key="next" className="next">
                        <Link to={`/p/${page}`}>Page précédente</Link>
                      </li>
                    )
                )}
              </ul>
            </nav>
          </section>
        )}
      </main>
    );
  }
}

BlogIndex.propTypes = {
  page: PropTypes.string.isRequired,
};

export default BlogIndex;
