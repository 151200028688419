import React, { Component } from "react";
import VideoView from "../pages/video-view";
import PostService from "../../services/post-service";

class VideoPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      posts: [],
    };
  }

  componentDidMount() {
    this.getLastVideoPost();
  }
  getLastVideoPost() {
    PostService.getRandomVideoPost()
      .then((response) => {
        this.setState({
          posts: response.data.arrPosts,
        });
      })
      .catch((error) => {});
  }
  // 2. Fonction pour mettre à jour l'état lors du clic sur l'image
  handleImageClick = () => {};
  render() {
    return (
      <div>
        <section className="text-center mb-2 page-sidebar-article">
          {this.state.posts.map((post) => (
            <VideoView key={post.publicID} post={post} />
          ))}
        </section>
      </div>
    );
  }
}

export default VideoPreview;
