import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";

class BlogFullIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      loading: true,
      title: "Chargement...",
    };
    this.page = 1;
    this.previousPage = 0;
  }

  componentDidMount() {
    this.setPage();
    this.displayPosts(this.page);
    this.previousPage = this.page;
  }

  componentDidUpdate(prevProps) {
    this.displayPage(prevProps);
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    }
  }
  displayPage(prevProps) {
    this.setPage();

    if (this.page !== this.previousPage) {
      this.displayPosts(this.page);
      this.previousPage = this.page;
      window.scrollTo(0, 0);
    }
  }
  displayPosts(index) {
    if (index > 0) {
      this.setState({
        loading: true,
      });
      PostService.getPosts(index, 50)
        .then((responseData) => {
          const posts = responseData.data.results.posts;
          let previousPeriod = 0;
          for (let i = 0; i < posts.length; i++) {
            if (posts[i].period !== previousPeriod) {
              previousPeriod = posts[i].period;
            } else {
              posts[i].period = null;
            }
          }
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
            title: "Page " + responseData.data.results.p,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            posts: [],
            pagination: [],
            title: error,
            loading: false,
          });
        });
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Aucune page définie",
      });
    }
  }
  render() {
    if (this.state.posts.length === 0 && this.state.loading) {
      return (
        <main className="page-main medium-9 cell">
          <LoadingBar />
        </main>
      );
    }
    return (
      <main className="page-main medium-9 cell">
        {this.state.posts.length === 0 && !this.state.loading && (
          <ErrorBlock message={this.state.title} />
        )}

        {this.state.posts.length > 0 && (
          <div>
            <nav className="pagination text-center">
              <ul>
                {this.page !== 1 && (
                  <li className="next">
                    <Link to={`/liste-complete/1`}>Retour page 1</Link>
                  </li>
                )}
                {this.state.pagination.map(
                  (page, index) =>
                    index === 0 &&
                    page !== "" && (
                      <li key="prev-1" className="prev">
                        <Link to={`/liste-complete/${page}`}>
                          Page suivante
                        </Link>
                      </li>
                    )
                )}
                {this.state.pagination.map(
                  (page, index) =>
                    index === 1 &&
                    page !== "" && (
                      <li key="next-1" className="next">
                        <Link to={`/liste-complete/${page}`}>
                          Page précédente
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </nav>

            <div className="card">
              <div className="p-1">
                <div className="card-section">
                  <ul className="menu vertical mb-3">
                    {this.state.posts.map((post) => (
                      <li key={post.publicID}>
                        {post.period !== null && (
                          <div className="h6 ml-2 mt-2 mb-2">{post.period}</div>
                        )}
                        <div className="border-light">
                          <Link to={`/page/${post.publicID}.html`}>
                            {post.title} - <span>{post.dateDay}</span>
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="text-center mb-1">{this.state.title}</div>
            <nav
              className="pagination mb-2 text-center"
              role="navigation"
              aria-label="Pagination"
            >
              <ul>
                {this.page !== 1 && (
                  <li className="next">
                    <Link to={`/liste-complete/1`}>Retour page 1</Link>
                  </li>
                )}

                {this.state.pagination.map(
                  (page, index) =>
                    index === 0 &&
                    page !== "" && (
                      <li key="prev" className="prev">
                        <Link to={`/liste-complete/${page}`}>
                          Page suivante
                        </Link>
                      </li>
                    )
                )}

                {this.state.pagination.map(
                  (page, index) =>
                    index === 1 &&
                    page !== "" && (
                      <li key="next" className="next">
                        <Link to={`/liste-complete/${page}`}>
                          Page précédente
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </nav>
          </div>
        )}
      </main>
    );
  }
}

BlogFullIndex.propTypes = {
  page: PropTypes.string.isRequired,
};

export default BlogFullIndex;
