import React, { Component } from "react";
import { Link } from "react-router-dom";
import MainNav from "./main-nav";

class BlogHeader extends Component {
  render() {
    return (
      <header
        role="banner"
        id="page-header"
        className="medium-12 columns cell page-header"
      >
        <Link title="accueil du blog" to="/">
          <h1 className="page-title" itemProp="name">
            {process.env.REACT_APP_TITLE}
          </h1>
        </Link>

        <MainNav />
      </header>
    );
  }
}

export default BlogHeader;
