import React, { Component } from "react";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";

class EditorLabelList extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      searchLabel: "",
      labels: this.props.labels,
      newLabels: [],
      post: this.props.post,
      status: "",
    };

    this.state.post = this.props.post;

    this.deleteLabel = this.deleteLabel.bind(this);
    this.addLabel = this.addLabel.bind(this);
    this.handleChangeSearchLabel = this.handleChangeSearchLabel.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      typeof this.props.post !== "undefined" &&
      typeof this.props.post.publicID !== "undefined" &&
      this.props.post.publicID !== this.state.post.publicID
    ) {
      this.setState({
        labels: this.props.labels,
        post: this.props.post,
      });
    }
  }
  componentDidMount() {
    this.setState({
      labels: this.props.labels,
    });
  }
  addLabel(label) {
    PostService.addPostLabel(this.props.post.publicID, label.publicID)
      .then((responseData) => {
        if (typeof responseData.data.status !== "undefined") {
          const labels = this.state.labels;
          labels.push(label);

          const newLabels = this.state.newLabels;
          for (let i = 0; i < newLabels.length; i++) {
            if (newLabels[i].publicID === label.publicID) {
              newLabels.splice(i, 1);
            }
          }

          this.setState({
            status: responseData.data.status,
            labels: labels,
            newLabels: newLabels,
          });
        }
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });
  }
  deleteLabel(label) {
    PostService.deletePostLabel(this.props.post.publicID, label.publicID)
      .then((responseData) => {
        if (typeof responseData.data.status !== "undefined") {
          const labels = this.state.labels;
          for (let i = 0; i < labels.length; i++) {
            if (labels[i].publicID === label.publicID) {
              labels.splice(i, 1);
            }
          }

          this.setState({
            status: responseData.data.status,
            labels: labels,
          });
        }
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });
  }
  handleChangeSearchLabel(event) {
    const search = event.target.value;

    this.setState({
      searchLabel: search,
    });

    if (search !== "" && search.length > 2) {
      PostService.searchLabels({ search: search })
        .then((responseData) => {
          if (
            typeof responseData.data.results !== "undefined" &&
            typeof responseData.data.results.labels !== "undefined" &&
            responseData.data.results.labels !== null
          ) {
            this.setState({
              newLabels: responseData.data.results.labels,
            });
          }
        })
        .catch((error) => {
          this.setState({
            posts: [],
            title: error.message,
          });
        });
    }
  }
  render() {
    return (
      <fieldset className="mb-2" id="labels">
        <legend>Libellés</legend>

        {this.state.labels.length > 0 && (
          <div>
            <div className="labels grid-padding-x medium-12 columns cell">
              <ul className="menu horizontal">
                {this.state.labels.map((label) => (
                  <li className="mb-1" key={label.publicID}>
                    <button
                      type="button"
                      title="Supprimer"
                      className="m-0_5 button warning"
                      onClick={() => this.deleteLabel(label)}
                    >
                      <span>{label.title}</span> - X
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <p>{this.state.status}</p>
          </div>
        )}
        <div className="grid-padding-x medium-12 columns cell add">
          <div>
            <label htmlFor="new-label">Nouveau libellé :</label>
            <input
              value={this.state.searchLabel}
              onChange={this.handleChangeSearchLabel}
              id="label-input"
              placeholder="libellé"
              type="text"
              name="label-list"
              list="label-list"
            />
            <datalist id="label-list">
              {this.state.newLabels.map((label) => (
                <option
                  key={label.publicID}
                  value={label.title}
                  label={label.title}
                ></option>
              ))}
            </datalist>
          </div>
          {this.state.newLabels.length > 0 && (
            <ul className="menu horizontal mb-2">
              {this.state.newLabels.map((label) => (
                <li key={label.publicID}>
                  <button
                    type="button"
                    title="ajouter"
                    className="m-0_5 button"
                    onClick={() => this.addLabel(label)}
                  >
                    {label.title} +
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </fieldset>
    );
  }
}

EditorLabelList.propTypes = {
  labels: PropTypes.array.isRequired,
  post: PropTypes.object.isRequired,
};

export default EditorLabelList;
