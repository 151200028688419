import React, { Component } from "react";
import { Link } from "react-router-dom";
import PostService from "../../services/post-service";

class EditorPostsList extends Component {
  constructor(props) {
    super(props);
    this.updateCounter = 0;
    this.props = props;
    this.state = {
      posts: [],
    };
  }
  componentDidUpdate() {
    if (this.updateCounter !== this.props.updateCounter) {
      this.updateCounter = this.props.updateCounter;
      this.displayPosts(1);
    }
  }
  componentDidMount() {
    this.displayPosts(1);
  }

  displayPosts(index) {
    PostService.getPosts(index, 12)
      .then((responseData) => {
        if (
          typeof responseData.data.results !== "undefined" &&
          typeof responseData.data.results.posts !== "undefined"
        ) {
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
          });
        }
      })
      .catch((error) => {});
  }
  render() {
    return (
      <aside>
        <article className="mb-1 is-anchored is-at-top">
          <h1 className="h5">Liste d'articles</h1>
          <ol className="menu vertical mb-1">
            {this.state.posts.map((post) => (
              <li className="border-light" key={post.publicID}>
                <Link className="style-lien" to={`/editor/${post.publicID}`}>
                  {post.title}
                </Link>
              </li>
            ))}
          </ol>

          <nav className="pagination text-center">
            <ul className="menu horizontal">
              <li>
                <button
                  type="button"
                  onClick={() => this.displayPosts(1)}
                  className="hollow button"
                >
                  1
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => this.displayPosts(2)}
                  className="hollow button"
                >
                  2
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => this.displayPosts(3)}
                  className="hollow button"
                >
                  3
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => this.displayPosts(4)}
                  className="hollow button"
                >
                  4
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => this.displayPosts(5)}
                  className="hollow button"
                >
                  5
                </button>
              </li>
            </ul>
          </nav>
        </article>
      </aside>
    );
  }
}

export default EditorPostsList;
