import React, { Component } from "react";
import { Link } from "react-router-dom";
import BlogIndexPost from "./blog-index-post";
import PostService from "../../services/post-service";
import PropTypes from "prop-types";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";

class BlogCategoryIndex extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      posts: [],
      pagination: [],
      page: 1,
      loading: true,
      title: "Chargement...",
    };
    this.page = 1;
    this.previousPage = 0;
    this.previousId = "";
    this.id = "";

    this.setPage();

    this.setId();
  }
  componentDidUpdate(prevProps) {
    this.displayPage(prevProps);
  }

  componentDidMount() {
    this.displayPage();
  }
  setPage() {
    if (typeof this.props.page !== "undefined") {
      this.page = this.props.page;
    } else {
      this.page = 1;
    }
  }
  setId() {
    if (typeof this.props.id !== "undefined") {
      this.id = this.props.id;
    } else {
      this.id = "";
    }
  }
  displayPage(prevProps) {
    this.setId();
    this.setPage();

    if (this.id !== this.previousId) {
      this.setState({
        posts: [],
        title: "",
      });
    }
    if (this.page !== this.previousPage || this.id !== this.previousId) {
      //this.displayPage = this.displayPage.bind(this);
      this.displayPosts(this.page, this.id);
      this.previousPage = this.page;
      this.previousId = this.id;
      window.scrollTo(0, 0);
    }
  }

  displayPosts(index, id) {
    if (index > 0 && id !== "") {
      this.setState({
        loading: true,
      });
      PostService.getCategoryPosts(index, this.id, 10)
        .then((responseData) => {
          this.setState({
            posts: responseData.data.results.posts,
            pagination: responseData.data.results.pagination,
            title: responseData.data.results.title,
            page: responseData.data.results.p,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            posts: [],
            pagination: [],
            title: error,
            page: 1,
            loading: false,
          });
        });
    } else {
      this.setState({
        posts: [],
        pagination: [],
        title: "Catégorie non définie",
      });
    }
  }
  render() {
    return (
      <div className="page-main medium-9 cell">
        {this.state.posts.length === 0 && this.state.loading && <LoadingBar />}
        {this.state.posts.length === 0 && !this.state.loading && (
          <ErrorBlock message={this.state.title} />
        )}
        {this.state.posts.length > 0 && (
          <div>
            <main>
              <h2 className="text-center h5 mb-1">{this.state.title}</h2>
              {this.state.posts.map((post) => (
                <BlogIndexPost key={post.publicID} post={post} />
              ))}
            </main>

            <div className="text-center mb-1">Page {this.state.page}</div>

            <nav className="pagination text-center">
              <ul>
                {this.page > 1 && (
                  <li className="next">
                    <Link to={`/categorie/${this.id}/1.html`}>
                      Retour page 1
                    </Link>
                  </li>
                )}
                {this.state.pagination.map(
                  (page, index) =>
                    index === 0 &&
                    page !== "" && (
                      <li key="prev" className="prev">
                        <Link to={`/categorie/${this.id}/${page}.html`}>
                          Page suivante
                        </Link>
                      </li>
                    )
                )}

                {this.state.pagination.map(
                  (page, index) =>
                    index === 1 &&
                    page !== "" && (
                      <li key="next" className="next">
                        <Link to={`/categorie/${this.id}/${page}.html`}>
                          Page précédente
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

BlogCategoryIndex.propTypes = {
  page: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default BlogCategoryIndex;
