const DraftPostService = {
	getDraft(draftKey) {
		const draftData = localStorage.getItem(draftKey);
		if (draftData != null) {
			const draft = JSON.parse(draftData);
			draft.draftIndex = "";
			draft.publicID = "";
			return draft;
		}
		return null;
	},
	getPrefix(type) {
		switch (type) {
			case 2:
				return "post_published_";
			case 3:
				return "post_history_";
			default:
				return "post_draft_";
		}
	},
	getDrafts(type) {
		const prefix = this.getPrefix(type);
		const drafts = [];
		for (let key in localStorage) {
			if (key.indexOf(prefix) >= 0) {
				const draft = JSON.parse(localStorage.getItem(key));
				if (typeof draft.title === "undefined" || draft.title === null || draft.title === "") {
					draft.title = "----";
				}
				if (typeof draft.type === "undefined" || draft.type === null) {
					draft.type = 1;
				}
				if (typeof draft.draftIndex === "undefined" || draft.draftIndex === null) {
					draft.draftIndex = key.replace(prefix, "");
				}
				if (typeof draft.draftKey === "undefined" || draft.draftKey === null) {
					draft.draftKey = key;
				}
				if (draft.type === type && (draft.type !== 2 || draft.publicID !== "")) {
					drafts.push(draft);
				}
			}
		}

		if (type === 1) {
			// order by title
			drafts.sort(function(a, b) {
				return a.title > b.title;
			});
		}
		if (type === 2 || type === 3) {
			// order by title
			drafts.sort(function(a, b) {
				return a.dateIso < b.dateIso;
			});
		}
		return drafts;
	},
	downloadDraft(id) {
		if (id === null || id === 0) {
			return false;
		}

		const draft = this.getDraft(id);
		if (draft !== null && (draft.body)) {
			const html = '<!DOCTYPE html><html><head><meta content="text/html; charset=UTF-8" http-equiv="Content-Type"/></head><body><h1>' +
				draft.title + '</h1><main>' + draft.body + '</main></body></html>';
			const textFileAsBlob = new Blob([html], {
				type: 'text/html'
			});
			const fileNameToSaveAs = draft.title + '.html';
			const downloadLink = document.createElement('a');
			downloadLink.download = fileNameToSaveAs;
			downloadLink.innerHTML = 'Download File';
			if (typeof window.webkitURL !== 'undefined') {
				downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
			} else {
				// Firefox requires the link to be added to the DOM
				// before it can be clicked.
				downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
				downloadLink.style.display = "none";
				document.body.appendChild(downloadLink);
			}
			downloadLink.click();
		};
	},
	deleteDraft(draftKey) {
		if (this.testLocalStorage() === false) {
			return false;
		}
		if (draftKey === null || draftKey === "") {
			return false;
		}
		localStorage.removeItem(draftKey);
		return true;
	},
	deleteOldDrafts(type, nb) {
		const lastIndex = this.getLastDraftIndex(type) - nb;
		const drafts = this.getDrafts(type);
		for (let i = 0; i < drafts.length; i++) {
			if (drafts[i].type === type && drafts[i].draftIndex < lastIndex) {
				localStorage.removeItem(drafts[i].draftKey);
			}
		}
	},
	createDraft(post, type, index) {
		if (index === -1) {
			// increment index
			index = this.getLastDraftIndex(type) + 1;
		}
		const prefix = this.getPrefix(type);
		if (this.testLocalStorage() === false) {
			return 0;
		}
		const d = new Date();
		post.date_creation = d.toLocaleString("fr-FR");
		post.dateIso = d.toISOString();
		post.draftKey = prefix + index;
		post.draftIndex = index;

		// set local storage
		localStorage.setItem(post.draftKey, (JSON.stringify(post)));
		return index;
	},
	deleteDrafts() {
		for (let key in localStorage) {
			if (key.indexOf("post_") >= 0) {
				localStorage.removeItem(key);
			}
		}
	},
	downloadDrafts() {
		for (let key in localStorage) {
			if (key.indexOf("post_") >= 0) {
				this.downloadDraft(key);
			}
		}
	},
	testLocalStorage() {
		if (typeof(Storage) === 'undefined') {
			this.setState({
				status: "Votre navigateur ne supporte pas le stockage local"
			});
			return false;
		} else {
			return true;
		}
	},
	getLastDraftIndex(type) {
		const prefix = this.getPrefix(type);
		let index = 0;
		let max = 2;
		for (let key in localStorage) {
			if (key.indexOf(prefix) >= 0) {
				index = parseInt(key.replace(prefix, ''));
				if (index > max) {
					max = index;
				}
			}
		}
		return max;
	}
}

export default DraftPostService;