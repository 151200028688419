import React, { Component } from "react";
import PropTypes from "prop-types";

class ErrorBlock extends Component {
  constructor(props) {
    super(props);
    this.message = props.message;
  }
  render() {
    return (
      <article className="card">
        <div className="card-section">
          <h2 className="text-center h5 mb-1">{this.props.message}</h2>
        </div>
      </article>
    );
  }
}

ErrorBlock.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorBlock;
