import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import BackupPostsList from "./backup-posts-list";
import EditorPostsList from "./editor-posts-list";
import EditorPost from "./editor-post";
import EditorLabelList from "./editor-label-list";
import AuthenticationService from "../../services/authentication";
import PostService from "../../services/post-service";
import DraftPostService from "../../services/draft-post-service";
import { useParams } from "react-router-dom";

class Editor extends Component {
  _isMounted = false;
  draftCounter = 0;
  editorCounter = 0;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      value: null,
      status: "",
      updateStatus: "",
      displayBody: false,
      post: this.getNewPost(),
      searchLabel: "",
      labels: [],
      words: [],
    };

    this.draftCounter = 0;
    this.updateCounter = 1;
    this.publicID = "";

    if (
      typeof this.props.params !== "undefined" &&
      typeof this.props.params.publicID !== "undefined"
    ) {
      this.publicID = this.props.params.publicID;
    }

    DraftPostService.deleteOldDrafts(2, 8);
    DraftPostService.deleteOldDrafts(3, 15);
    this.displayDraft = this.displayDraft.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayNewPost = this.displayNewPost.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.countWords = this.countWords.bind(this);
  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      this._isMounted = false;
      this.createEditorDraft(this.state.post);
      return true;
    });
  };
  componentDidMount() {
    this.setupBeforeUnloadListener();
    this._isMounted = true;
    if (this.publicID === "NEW") {
      this.displayNewPost("Nouvel article");
    } else if (this.publicID !== "" && this.publicID !== "0") {
      this.displayPost(this.publicID);
    } else {
      this.displayNewPost("Nouvel article");
    }
    window.scrollTo(0, 0);
    return true;
  }
  componentDidUpdate() {
    if (this._isMounted) {
      if (
        typeof this.props.params !== "undefined" &&
        typeof this.props.params.draftKey !== "undefined" &&
        this.props.params.draftKey !== ""
      ) {
        this.displayDraft(this.props.params.draftKey);
      } else if (
        typeof this.props.params !== "undefined" &&
        typeof this.props.params.publicID !== "undefined" &&
        this.props.params.publicID !== this.publicID
      ) {
        if (
          this.props.params.publicID !== this.publicID &&
          this.publicID === "NEW"
        ) {
          this.createEditorDraft(this.state.post);
        }

        this.publicID = this.props.params.publicID;

        if (
          this.props.params.publicID === "NEW" ||
          this.props.params.publicID === ""
        ) {
          this.displayNewPost("Nouvel article");
        } else {
          this.displayPost(this.publicID);
        }
        window.scrollTo(0, 0);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.createEditorDraft(this.state.post);
  }
  getNewPost() {
    const d = new Date();
    return {
      publicID: "",
      title: "",
      date: "",
      body: "...",
      date_creation: d.toLocaleString("fr-FR"),
      datetime: "",
      dateDaySlash: "",
      description: "",
      idTrack: 0,
      draftIndex: "",
      draftKey: "",
      site: 0,
      dateDay: "",
      date_y_m_d: "",
      labels: [],
      type: 1,
    };
  }
  displayNewPost(status) {
    this.setState({
      post: this.getNewPost(),
      status: status,
      updateStatus: "",
    });
    return true;
  }
  displayDraft(draftKey) {
    this.publicID = "";
    this.props.params.draftKey = "";
    this.props.params.publicID = "";

    if (draftKey === null || draftKey === "") {
      this.setState({
        status: "Brouillon non disponible",
        updateStatus: "",
      });
      return false;
    }

    const draft = DraftPostService.getDraft(draftKey);
    if (draft !== null) {
      this.setState({
        post: draft,
        status: "Brouillon",
        updateStatus: "",
      });
      window.scrollTo(0, 0);
    } else {
      this.setState({
        status: "Brouillon non défini",
        updateStatus: "",
      });
    }
    return true;
  }
  displayPost(publicID) {
    if (publicID !== "" && publicID !== "0" && publicID !== "NEW") {
      PostService.getPost(publicID, this.state.post.site)
        .then((response) => {
          if (
            typeof response.data.results !== "undefined" &&
            typeof response.data.results.post !== "undefined"
          ) {
            this.setState({
              post: response.data.results.post,
              status: "Publier",
              updateStatus: "",
            });
          } else {
            let status = "";
            if (typeof response.data.status !== "undefined") {
              status = response.data.status;
            }
            this.setState({
              status: status + " - " + response.data.responseDate,
            });

            this.displayNewPost("Article introuvable");
          }
        })
        .catch((error) => {
          this.setState({
            status: error.message,
          });
        });
    } else {
      this.setState({
        status: "Article non défini",
      });
    }
  }
  deletePost() {
    if (window.confirm("Supprimer l'article ?") === false) {
      return false;
    }
    if (AuthenticationService.checkLocalAuthUser() === false) {
      this.setState({
        updateStatus: "Non autorisé",
      });
      return false;
    }

    const publicID = this.state.post.publicID;
    if (publicID !== "") {
      this.createEditorDraft(this.state.post);
      PostService.deletePost(publicID)
        .then((response) => {
          let status = "";
          if (typeof response.data.status !== "undefined") {
            status = response.data.status;
          }
          this.setState({
            updateStatus: status,
          });
          this.updateCounter++;

          this.props.navigate("/editor/NEW");
        })
        .catch((error) => {
          this.setState({
            updateStatus: error.message,
          });
        });
    } else {
      this.setState({
        updateStatus: "Article non défini",
      });
    }
  }
  handleSubmit(event) {
    if (AuthenticationService.checkLocalAuthUser() === false) {
      this.setState({
        updateStatus: "Non autorisé",
      });
      return false;
    }

    this.setState({
      updateStatus: "...",
    });

    this.createEditorDraft(this.state.post);

    PostService.savePost(this.state.post)
      .then((response) => {
        let status = "";
        if (typeof response.data.status !== "undefined") {
          status = response.data.status;
        }

        this.setState({
          updateStatus: status,
        });

        window.alert(status);

        this.updateCounter++;
        if (
          typeof response.data.results !== "undefined" &&
          typeof response.data.results.post !== "undefined"
        ) {
          if (
            typeof response.data.results.publicID !== "undefined" &&
            response.data.results.publicID !== ""
          ) {
            this.props.params.publicID = response.data.results.publicID;
            this.createDraft(this.state.post, 2, -1);
            this.props.navigate("/editor/" + this.props.params.publicID);
          }
        }
      })
      .catch((error) => {
        this.setState({
          updateStatus: error.message,
        });
        window.alert(error.message);
      });

    event.preventDefault();
    return true;
  }
  handleChange(event) {
    const post = this.state.post;

    switch (event.target.name) {
      case "post-publicID":
        post.publicID = event.target.value;
        this.setState({
          post: post,
        });
        this.publicID = event.target.value;
        if (this.publicID !== "") {
          this.displayPost(this.publicID);
        }
        return true;
      case "post-site":
        post.site = event.target.value;
        break;
      case "post-title":
        if (post.title.length > 5) {
          this.createEditorBodyDraft(post);
        }
        post.title = event.target.value;
        break;
      case "post-body":
        post.body = event.target.value;
        if (post.body.length > 10) {
          this.createEditorBodyDraft(post);
        }
        break;
      case "post-date":
        post.date_y_m_d = event.target.value;
        post.date = event.target.value;
        break;
      default:
        break;
    }

    this.setState({
      post: post,
    });
  }
  createEditorDraft(post) {
    if (post.body.length > 5) {
      let index = DraftPostService.getLastDraftIndex(3);
      if (index > 15 || index < 5) {
        index = 5;
      } else {
        index = -1;
      }
      this.createDraft(post, 3, index);
    }
  }
  createEditorBodyDraft(post) {
    this.editorCounter++;
    if (this.editorCounter > 4) {
      this.editorCounter = 0;
    }
    this.createDraft(post, 3, this.editorCounter);
  }
  createDraft(post, type, index) {
    this.draftCounter++;
    post.type = type;
    index = DraftPostService.createDraft(post, type, index);
    if (
      this._isMounted &&
      typeof this.props.params.draftKey === "undefined" &&
      this.publicID === "NEW"
    ) {
      this.props.navigate("/draft/" + index);
    }
  }
  getWords(body) {
    const words = [];
    const tabCompile = [];

    const regexTag = /(<([^>]+)>)/gi;
    const regex = new RegExp("[ '-]+", "g");
    const tabWords = body.split(regex);
    for (let i = 0; i < tabWords.length; i++) {
      const word = tabWords[i]
        .replace(regexTag, "")
        .replace(",", "")
        .replace("'", "")
        .replace(".", "")
        .replace("(", "")
        .replace(")", "");
      if (word.length > 3) {
        if (typeof tabCompile[word] === "undefined") {
          tabCompile[word] = 1;
        } else {
          tabCompile[word]++;
        }
      }
    }

    for (let word in tabCompile) {
      if (tabCompile[word] > 2) {
        words.push({
          text: word,
          nb: tabCompile[word],
        });
      }
    }
    return words;
  }
  countWords() {
    const words = this.getWords(this.state.post.body);
    words.sort(function (a, b) {
      return a.nb < b.nb;
    });
    this.setState({
      words: words,
    });
    return true;
  }
  render() {
    if (AuthenticationService.checkLocalAuthUser() === false) {
      return (
        <div className="page-page medium-9 cell">
          <main className="page-main">Accès non autorisé</main>
        </div>
      );
    }

    return (
      <div className="page-page medium-9 cell">
        <main className="page-main">
          {this.state.post.title !== "" && (
            <div className="mb-2">
              <EditorPost post={this.state.post} />
            </div>
          )}

          <article className="post mb-2" id="post-editor">
            <form
              onSubmit={this.handleSubmit}
              className="mb-1"
              name="post-editor-form"
            >
              <fieldset className="mb-1" id="edit-fields">
                <legend>{this.state.status}</legend>
                <ol className="menu vertical">
                  <li className="mb-1">
                    <label htmlFor="post-title">
                      Titre
                      <input
                        type="text"
                        id="post-title"
                        name="post-title"
                        value={this.state.post.title}
                        onChange={this.handleChange}
                        className="titleField"
                        placeholder="Entrez un titre"
                        required="required"
                        maxLength="100"
                      />
                    </label>
                  </li>
                  <li className="mb-1">
                    <label htmlFor="post-date">
                      Date
                      <input
                        id="post-date"
                        name="post-date"
                        value={this.state.post.date_y_m_d}
                        onChange={this.handleChange}
                        type="date"
                        required="required"
                      />
                    </label>
                  </li>
                  <li className="mb-1">
                    <label htmlFor="post-body">Contenu</label>
                    <textarea
                      id="post-body"
                      name="post-body"
                      value={this.state.post.body}
                      onChange={this.handleChange}
                      maxLength="100000"
                      cols="66"
                      rows="30"
                      aria-multiline="true"
                      required="required"
                    ></textarea>
                  </li>
                  <li className="mb-1">
                    <label htmlFor="post-description">Description</label>
                    <textarea
                      id="post-description"
                      name="post-description"
                      value={this.state.post.description}
                      onChange={this.handleChange}
                      maxLength="1000"
                      rows="3"
                    ></textarea>
                  </li>
                  <li className="mb-1">
                    <label htmlFor="track-id">ID titre album</label>
                    <input
                      id="track-id"
                      type="number"
                      name="track-id"
                      value={this.state.post.idTrack}
                      onChange={this.handleChange}
                      min="0"
                      max="90000"
                      step="1"
                    />
                  </li>
                  <li className="mb-1">
                    <label htmlFor="post-site">Site</label>
                    <input
                      type="number"
                      id="post-site"
                      name="post-site"
                      value={this.state.post.site}
                      onChange={this.handleChange}
                      placeholder="site"
                      required="required"
                      min="0"
                      max="9"
                      step="1"
                    />
                  </li>

                  <li className="mb-1">
                    <label htmlFor="post-publicID">ID</label>
                    <input
                      type="text"
                      id="post-publicID"
                      name="post-publicID"
                      value={this.state.post.publicID}
                      onChange={this.handleChange}
                      placeholder="section"
                      maxLength="100"
                    />
                  </li>
                </ol>
              </fieldset>

              <fieldset className="mb-1" id="choice">
                <ol className="menu horizontal">
                  {this.state.post.title !== "" && (
                    <li>
                      <button
                        id="submit"
                        type="submit"
                        name="submit"
                        className="m-0_5 button success"
                      >
                        Envoyer
                      </button>
                    </li>
                  )}
                  <li>
                    <Link className="m-0_5 button" to={`/editor/NEW`}>
                      Nouveau
                    </Link>
                  </li>

                  {this.state.post.publicID !== "" && (
                    <li data-ng-show="billet.id > 0">
                      <button
                        type="button"
                        className="m-0_5 button alert"
                        onClick={() => this.deletePost()}
                        id="supprimer"
                        title="supprimer l'article"
                      >
                        Supprimer
                      </button>
                    </li>
                  )}
                </ol>

                <p className="mb-2">{this.state.updateStatus}</p>
              </fieldset>

              {this.state.post.publicID !== "" && (
                <div>
                  <EditorLabelList
                    post={this.state.post}
                    labels={this.state.post.labels}
                  />
                </div>
              )}

              <div className="mb-1">
                <button
                  type="button"
                  className="button"
                  id="count-words"
                  name="m-0_5 count-words"
                  onClick={() => this.countWords()}
                >
                  Compter mots
                </button>

                <div className="mb-2">
                  {this.state.words.map((word, index) => (
                    <span
                      key={index}
                      className={"mrm label-size label-size-" + word.nb}
                    >
                      {word.text} ({word.nb})
                    </span>
                  ))}
                </div>
              </div>
            </form>
          </article>
          <div className="card">
            <div className="card-section">
              <BackupPostsList
                post={this.state.post}
                draftCounter={this.draftCounter}
                displayDraft={this.displayDraft}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-section">
              <EditorPostsList updateCounter={this.updateCounter} />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default (props) => (
  <Editor {...props} navigate={useNavigate()} params={useParams()} />
);
