import axios from 'axios';
import AuthenticationService from './authentication';

const DataService = {
	getObjectDefinition(objectType, sectionID) {
		const data = {
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			userName: AuthenticationService.getLocalUserName(),
			regenerateAuth: 1,
			data: {
				sectionID: sectionID,
				objectType: objectType
			}
		};

		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"));
				return false;
			}
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER_URL + "/gestion/data/display-form.jsonp",
				data: data
			}).then(function(response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else if (AuthenticationService.checkLocalAuthUser() === false) {
					reject(new Error("Utilisateur non autorisé"));
				} else {
					resolve(response);
				}
			}).catch(
				function(error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	},
	updateObject(objectType, objectData, action, sectionID, recordID, recordPublicID) {
		const data = {
			userName: AuthenticationService.getLocalUserName(),
			userKey: AuthenticationService.getLocalUserKey(),
			requestKey: AuthenticationService.getRequestKey(),
			publicKey: AuthenticationService.getPublicKey(),
			regenerateAuth: 1,
			data: {
				objectData: objectData,
				objectType: objectType,
				action: action,
				sectionID: sectionID,
				recordID: recordID,
				recordPublicID: recordPublicID
			}
		};

		return new Promise((resolve, reject) => {
			if (AuthenticationService.checkAuthRequest(data) === false) {
				reject(new Error("Non autorisé"));
				return false;
			}
			axios({
				method: 'post',
				url: process.env.REACT_APP_SERVER_URL + "/gestion/data/validation.jsonp",
				data: data
			}).then(function(response) {
				if (AuthenticationService.checkResponse(response, data.requestKey, true) === false) {
					reject(new Error("Erreur du serveur"));
				} else if (AuthenticationService.checkLocalAuthUser() === false) {
					reject(new Error("Utilisateur non autorisé"));
				} else {
					resolve(response);
				}
			}).catch(
				function(error) {
					reject((typeof error.message !== "undefined") ? error.message : "Erreur rencontrée");
				}
			);
		})
	}
}

export default DataService;