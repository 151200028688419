import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthenticationService from "../../services/authentication";

class MainNav extends Component {
  render() {
    return (
      <nav className="page-main-navigation" role="navigation">
        <ul className="menu horizontal" aria-labelledby="page-navigation">
          <li className="">
            <Link
              className="fade-in-up"
              title="accueil du blog"
              rel="home"
              to="/"
            >
              {process.env.REACT_APP_SUBTITLE}
            </Link>
          </li>

          {AuthenticationService.checkLocalAuthUser() && (
            <li>
              <Link className="fade-in-up" to="/editor/NEW">
                Editeur
              </Link>
            </li>
          )}

          {AuthenticationService.checkLocalAuthUser() && (
            <li>
              <Link className="fade-in-up" to="/admin-data">
                Admin
              </Link>
            </li>
          )}
          {AuthenticationService.checkLocalAuthUser() && (
            <li>
              <Link className="fade-in-up" to="/logoff">Déconnexion</Link>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default MainNav;
