import React, { Component } from "react";
import AuthenticationService from "../../services/authentication";
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        password: "",
        token: "",
      },
      isAuthenticated: false, // New state to track authentication
      status: AuthenticationService.getCurrentStatus(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setStatus = this.setStatus.bind(this);
  }
  handleSubmit(event) {
    AuthenticationService.login(this.state.user)
      .then((results) => {
        this.checkStatus(results);
        window.location.reload();
      })
      .catch((error) => {
        if (typeof error.message != "undefined") {
          this.setStatus(error.message);
        } else {
          this.setStatus(error);
        }
      });
    event.preventDefault();
  }
  checkStatus(results) {
    this.setStatus(results.message);
    if (results.auth.loginResult === true) {
      AuthenticationService.checkLogin(false)
        .then((message) => {
          this.setStatus(message);
        })
        .catch((error) => {
          this.setStatus(error.message);
        });
    }
  }
  setStatus(status) {
    this.setState({
      status: status,
    });
  }
  handleChange(event) {
    const user = this.state.user;
    if (event.target.name === "username") {
      user.username = event.target.value;
    }
    if (event.target.name === "password") {
      user.password = event.target.value;
    }
    this.setState({
      user: user,
    });
  }
  render() {
    window.scrollTo(0, 0);
    if (AuthenticationService.getIsLocalUser()) {
      return (
        <div className="page-main medium-9 cell">
          <main className="card">
            <article className="card-section post">
              <header className="post-header pam">
                <h1 className="text-center h5 mb-2">{this.state.status}</h1>
              </header>
              <section className="post-body">
                <p className="text-center">
                  <Link className="button" to={`/editor`}>
                    Publier
                  </Link>
                </p>
                <p className="text-center">
                  <Link className="button" to={`/admin-data`}>
                    Gestion des données
                  </Link>
                </p>
                <p className="text-center">
                  <Link className="button alert" to="/logoff">
                    Déconnexion
                  </Link>
                </p>
              </section>
            </article>
          </main>
        </div>
      );
    }
    return (
      <div className="page-main medium-9 cell">
        <main className="card">
          <article className="card-section post">
            <header className="post-header pam">
              <h1 className="text-center h5 mb-1">{this.state.status}</h1>
            </header>
            <section className="post-body">
              <form onSubmit={this.handleSubmit} name="post-editor-form">
                <fieldset>
                  <h2 className="h5 mb-1">Connexion</h2>
                  <div>
                    <label htmlFor="username" className="required">
                      Code utilisateur
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      value={this.state.user.username}
                      onChange={this.handleChange}
                      required="required"
                      maxLength="50"
                      className="input-lg"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="required">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={this.state.user.password}
                      onChange={this.handleChange}
                      required="required"
                      maxLength="50"
                      className="input-lg"
                    />
                  </div>
                  <div>
                    <button id="submit" name="submit" className="button">
                      Envoyer
                    </button>
                  </div>
                </fieldset>
              </form>
            </section>
          </article>
        </main>
      </div>
    );
  }
}

export default Login;
