import React, { Component } from "react";
import PostService from "../../services/post-service";
import BlogIndexPost from "./blog-index-post";
import LoadingBar from "../loading-bar";
class SearchPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      posts: [],
      loading: false,
      title: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    if (event.target.name === "search") {
      this.setState({
        search: event.target.value,
        posts: [],
        title: "Recherche en cours...",
      });
      this.displayPosts(event.target.value);
    }
  }
  handleSubmit(event) {
    event.preventDefault();
  }
  displayPosts(search) {
    if (search !== "" && search.length > 3) {
      this.setState({
        loading: true,
      });

      PostService.searchPosts({ search: search })
        .then((responseData) => {
          const posts = responseData.data.results.posts;
          posts.forEach((post) => {
            if (post.description !== null) {
              post.description = post.description.replace(
                search,
                "<mark>" + search + "</mark>"
              );
            }
          });

          this.setState({
            posts: responseData.data.results.posts,
            title: responseData.data.results.posts.length + " résultat(s)",
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            posts: [],
            title: error,
            loading: false,
          });
        });
    } else {
      this.setState({
        posts: [],
        title: "",
      });
    }
  }
  render() {
    return (
      <div className="page-main medium-9 cell">
        <main className="card">
          <article className="card-section">
            <form onSubmit={this.handleSubmit} role="search">
              <label className="mb-1" htmlFor="champ-recherche">
                Recherche
              </label>{" "}
              <input
                value={this.state.search}
                onChange={this.handleChange}
                id="search"
                required="required"
                maxLength="90"
                name="search"
                placeholder="Recherche"
                title="Mots-clé de la recherche"
                type="search"
              />
            </form>
          </article>
          <article className="card-section">
            <h2 className="text-center h5 mb-1">{this.state.title}</h2>
            {this.state.posts.map((post) => (
              <BlogIndexPost key={post.publicID} post={post} />
            ))}
          </article>
        </main>
      </div>
    );
  }
}

export default SearchPosts;
