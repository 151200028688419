import React from "react";
import { useParams } from "react-router-dom";
import BlogCategoryIndex from "./blog-category-index";

const BlogCategoryIndexContainer = () => {
  const { id, page } = useParams();
  return <BlogCategoryIndex id={id} page={page} />;
};

export default BlogCategoryIndexContainer;
