import React, { Component } from "react";
import BlogIndex from "./blog-index";

class BlogHome extends Component {
  render() {
    return <BlogIndex page="1" />;
  }
}

export default BlogHome;
