import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthenticationService from "../../services/authentication";

class BlogFooter extends Component {
  render() {
    return (
      <div className="page-footer page-footer-nav">
        <nav className="mb-1">
          <ul className="menu horizontal mb-1">
            <li>
              <span className="back-top">
                <a rel="nofollow" href="#page-header">
                  &#8593; Haut de page
                </a>
              </span>
            </li>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            {AuthenticationService.checkLocalAuthUser() && (
              <li>
                <Link to="/logoff">Déconnexion</Link>
              </li>
            )}
            <li>
              <Link to="/login">Connexion</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default BlogFooter;
