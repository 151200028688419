import React from "react";
import { useParams } from "react-router-dom";
import BlogPost from "./blog-post";

const BlogPostContainer = () => {
  const { publicID } = useParams();
  return <BlogPost publicID={publicID} />;
};

export default BlogPostContainer;
