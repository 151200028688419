import React, { Component } from "react";
import VideoPost from "./video-post";
import PostService from "../../services/post-service";
import LoadingBar from "../loading-bar";
import ErrorBlock from "../error-block";

class VideoPlayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: [],
      title: "Chargement...",
    };
  }
  displayPosts() {
    PostService.getVideoPosts()
      .then((response) => {
        this.setState({
          posts: response.data.arrPosts,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          posts: [],
          loading: false,
          title: error,
        });
      });
  }
  componentDidMount() {
    this.displayPosts(this.page);
  }
  render() {
    return (
      <div className="page-main medium-9 cell">
        {this.state.posts.length === 0 && this.state.loading && <LoadingBar />}
        {this.state.posts.length === 0 && !this.state.loading && (
          <ErrorBlock message={this.state.title} />
        )}
        {this.state.posts.length > 0 && !this.state.loading && (
          <main className="card">
            <section className="card-section">
              <h2 className="text-center h5 mb-1">Playlist</h2>
              {this.state.posts.map((post) => (
                <VideoPost key={post.publicID} post={post} />
              ))}
            </section>
          </main>
        )}
      </div>
    );
  }
}

export default VideoPlayList;
