import React from "react";
import { useParams } from "react-router-dom";
import BlogIndex from "./blog-index";

const BlogIndexContainer = () => {
  const { page } = useParams();
  return <BlogIndex page={page} />;
};

export default BlogIndexContainer;
