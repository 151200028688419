import React, { Component } from "react";
import PropTypes from "prop-types";
import VideoView from "./video-view";

class VideoPost extends Component {
  constructor(props) {
    super(props);
    this.post = props.post;
  }
  render() {
    return (
      <div>
        {this.post !== null && this.post.image.src && (
          <article className="post mb-2">
            <header>
              <time
                className="post-date"
                dateTime={this.post.dateIso}
                itemProp="datePublished"
              >
                {this.post.dateDay}
              </time>
              <h1
                className="post-title fs100 mb-1"
                itemProp="name"
                onClick={this.handleImageClick}
              >
                {this.post.title}
              </h1>
              <meta content="0" itemProp="interactionCount" />
            </header>
            <section className="post-body" itemProp="articleBody">
              <VideoView key={this.post.publicID} post={this.post} />
            </section>
          </article>
        )}
      </div>
    );
  }
}

VideoPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default VideoPost;
