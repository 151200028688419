import React, { Component } from "react";
import AuthenticationService from "../../services/authentication";
import PostService from "../../services/post-service";

class PostComment extends Component {
  constructor(props) {
    super(props);
    this.comment = props.comment;
    this.initialDateTime = props.initialDateTime;
    //this.handleSubmit = this.handleResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteComment = this.deleteComment.bind(this);

    if (this.comment.response === null) {
      this.comment.response = "";
    }
    this.state = {
      response: this.comment.response,
      status: "",
    };
  }
  handleResponse() {
    const comment = this.comment;
    comment.response = this.state.response;
    comment.title = "Réponse";
    PostService.postCommentResponse(comment, this.initialDateTime)
      .then((response) => {
        this.comment.response = this.state.response;
        if (typeof response.data.status !== "undefined") {
          this.setState({
            response: "",
            status: response.data.status + " - " + response.data.responseDate,
          });
        }
        if (typeof response.data.responseDate !== "undefined") {
          this.initialDateTime = response.data.responseDate;
        }
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });
    return true;
  }
  deleteComment() {
    PostService.deleteComment(this.comment.publicID)
      .then((responseData) => {
        this.comment.response = responseData.data.status;
        this.comment.publicID = 0;
        this.setState({
          response: this.state.response,
          status: responseData.data.results.status,
        });
      })
      .catch((error) => {
        this.setState({
          status: error.message,
        });
      });
    return true;
  }
  handleChange(event) {
    if (event.target.name === "comment-response") {
      this.setState({
        response: event.target.value,
      });
    }
  }
  render() {
    return (
      <article
        key={this.comment.publicID}
        className="card"
        itemProp="blogPost"
        itemScope="itemscope"
        itemType="http://schema.org/BlogPosting"
      >
        <header className="card-divider">
          <span className="">{this.comment.person.alias} - </span>

          <time itemProp="datePublished" dateTime={this.comment.dateIso}>
            {this.comment.dateDay} - {this.comment.hour}
          </time>
        </header>

        <div className="card-section">
          <section className="" itemProp="articleBody">
            {this.comment.body}
          </section>

          {this.comment.response !== "" && (
            <section className="mt-1 card">
              <header className="card-divider">Réponse</header>
              <section className="card-section post-commentaire-response">
                <div className="txtresponse">{this.comment.response}</div>
              </section>
            </section>
          )}
          {AuthenticationService.checkLocalAuthUser() && (
            <div>
              <section className="mt-1 card post-commentaire-response">
                <header className="card-divider">
                  Modifier/ajouter réponse
                </header>
                <section className="card-section" onChange={this.handleChange}>
                  <label htmlFor="body">
                    Réponse{" "}
                    <textarea
                      id="body"
                      rows="1"
                      name="comment-response"
                      value={this.state.response}
                      onChange={this.handleChange}
                      required="required"
                      maxLength="5000"
                      placeholder="Saisir un commentaire"
                    ></textarea>
                  </label>
                </section>

                <footer className="card-section">
                  <button
                    type="button"
                    onClick={() => this.handleResponse(this.comment.publicID)}
                    className="button"
                  >
                    Enregistrer
                  </button>
                </footer>
              </section>
              <footer>
                <button
                  type="button"
                  onClick={() => this.deleteComment(this.comment.publicID)}
                  className="button alert"
                >
                  Supprimer
                </button>
                <p>{this.state.status}</p>
              </footer>
            </div>
          )}
        </div>
      </article>
    );
  }
}

export default PostComment;
